import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  footerCtn: {
    position: 'absolute',
    bottom: '4%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#737472',
    display: 'flex',
    gap: '21px',
    alignItems: 'self-end',
    '& a': {
        fill: '#bbb',
        color: 'inherit',
        textDecoration: 'none'
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
    },
  },
  footerLinks: {
    display: 'flex',
    gap: '17px',
  }

}))
