
export const devSquad = [
    { value: 1, label: '[DS-API] - Finances', group: 'DevSquad'},
    { value: 2, label: 'Darvest', group: 'DevSquad'},
    { value: 3, label: 'Mindtools Cognito - SSO', group: 'DevSquad'},
    { value: 4, label: 'Trainee - Challenges', group: 'DevSquad'},
]
export const gamifica = [
  { value: 9, label: 'Gamifica - Wizard', group: 'Gamifica' },
]
export const mindTools = [
  { value: 5, label: '[30012-20853] Pen Test Remediations', group: 'Mindtools'},
  { value: 6, label: '[30012-20853] PENG-840 Toolkit Content Library Integration CSV: add category structure and content duration', group: 'Mindtools'},
  { value: 7, label: '[90020-00000] Agreed change to Learning Preferences Questionnaire - PENG-574', group: 'Mindtools'},
  { value: 8, label: '[90020-00000] AI Hackaton', group: 'Mindtools'},
]
export const tasks = [
  { value: 10, label: 'Dev: Development' },
  { value: 10, label: 'Dev: Pair Programming' },
  { value: 10, label: 'Dev: Planning' },
  { value: 10, label: 'Dev: Testing' },
  { value: 10, label: 'DevOps' },
  { value: 10, label: 'Documentation' },
]
export const projectGroupedOptions = [
    {
      label: 'DevSquad',
      options: devSquad,
    },
    {
      label: 'Gamifica',
      options: gamifica,
    },
    {
      label: 'MindTools',
      options: mindTools,
    }
]
export const taskGroupedOptions = [
  {
    label: 'Billable',
    options: tasks
  }
]