import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1,
    minHeight: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#1d1e1c',
    width: '100%'
  },
  toolBarLinks: {
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 600
  },
  navBox: {
    maxWidth: '1216px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  activeLink: {
    backgroundColor: '#fff'
  },
  toolBar: {
    justifyContent: 'center',
    backgroundColor: '#1d1e1c',
    minHeight: 48,
  },
  menu: {
    marginTop: 33
  },
  mobileMenu: {
    width: '100%',
    height: '100%',
    '& div': {
      top: '0 !important',
      left: '0 !important',
      width: '100%',
      maxWidth: '100%',
      height: '100vh',
      maxHeight: '100vh ',
      overflow: 'hidden',
      backgroundColor: '#1d1e1c'
    }
  },
  titleMobileMenu: {
    alignSelf: 'center',
    fontSize: 15
  },
  mobileMenuItensCtn: {
    padding: '10px 15px !important',
  },
  mobileMenuSignOutItensCtn: {
    background: '#343533 !important',
    borderRadius: '4px',
    margin: '16px 0',
    padding: '4px 16px',
    height: 'auto !important',
    foneSize: '15px'
  },
  closeBtnMenuMobile: {
    borderRadius: 6,
    fontSize: 13,
    height: 29,
    lineHeight: 29,
    padding: '0 8px',
    backgroundColor: '#fff',
    color: '#1d1e1c',
    textTransform: 'none',
    marginBottom: 15,
    '&:hover': {
      border: '1px solid #fff',
      backgroundColor: '#1d1e1c',
      color: '#fff'
    }
  },
  h2: {
    fontSize: 15,
    fontWeight: 600,
    marginLeft: 10,
    padding: 0
  },
  menuItem: {
    borderBottom: '1px solid',
    fontSize: 20,
    fontWeight: 500,
    padding: '0px',
    letterSpacing: 0.5,
    minHeight: '35px',
    borderColor: '#80808085 !important',
    '&:hover': {
      backgroundColor: '#1d1e1c',
      color: '#fff'
    }
  },
  signoutBtn: {
    fontSize: 15,
  },
  borderNone: {
      border: 'none',
    },
  p: {
    fontSize: 13,
    fontWeight: 300,
    marginLeft: 10,
    marginTop: -15,
    padding: 0
  }
}))

