import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  projectSelectBtn: {
    width: '100%',
    border: '1px solid rgba(29,30,28,.25)',
    marginBottom: '8px',
    display:'flex',
    justifyContent: 'space-between',
    lineHeight: 1.4,
    borderRadius: 7
  },
  taskSelectBtn: {
    width: '100%',
    height: 34,
    border: '1px solid rgba(29,30,28,.25)',
    marginBottom: '8px',
  },
  inputSearchItens: {
    left: '50%',
    transform: 'translate(-50%, 0%)',
    position: 'absolute',
    width: 550,
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    border: '1px solid rgba(29,30,28,.15)',
    boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
    borderRadius: 4,
    lineHeight: 1.4,
    color: 'black'
  },
  customOverlay: {
    backgroundColor: 'transparent !important', 
  },
  chosedSelectionCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  chosedSelectionGroupTitle: {
    textTransform: 'none',
    color: '#1d1e1cb3 !important',
    fontSize: '13px!important',
  },
  chosedSelectionProjectTitle: {
    textTransform: 'none',
    color: '#1d1e1c',
  },
  modalOpenBackground: {
    backgroundColor: '#1d1e1c12'
  },
}))
