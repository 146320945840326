import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    fontFamily: 'Muoto,-apple-system,BlinkMacSystemFont,Segoe UI,sans-serif',
    width: '100%',
    maxWidth: '1216px',
    margin: '0 auto',
    alignContent: 'flex-start',
    [theme.breakpoints.down(900)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  dateSectionContainer: {
    height: 50,
    flexDirection: 'column',
    [theme.breakpoints.down(900)]: {
      height: 'auto',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      '&.MuiGrid-grid-xs-12': {
        flexBasis: 'auto'
      }
    }
  },
  arrowTitle: {
    display: 'flex',
    marginLeft: 85,
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
      marginLeft: 0,
      '&.MuiGrid-grid-xs-12': {
        flexBasis: 'auto'
      }
    }
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
    height: 34,
    border: '1px solid rgba(29,30,28,.25)'
  },
  date: {
    fontSize: 29,
    fontWeight: 500,
    lineHeight: 1.25,
    margin: 0,
    marginLeft: 15,
    padding: 0,
    [theme.breakpoints.down(900)]: {
      marginLeft: 0,
      marginTop: 10
    }
  },
  leftArrow: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    '&:hover': {
      borderColor: '#1d1e1c'
    }
  },
  rightArrow: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    '&:hover': {
      borderColor: '#1d1e1c'
    }
  },
  calendarTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(900)]: {
      '&.MuiGrid-grid-xs-6': {
        flexBasis: 'auto',
        marginTop: 10
      }
    }
  },
  calendar: {
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 34,
    width: 34,
    borderRadius: '8px',
    border: '1px solid rgba(29,30,28,.25)',
    '&:hover': {
      borderColor: '#1d1e1c'
    }
  },
  dayOpt: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 14px',
    height: 34,
    backgroundColor: '#ffe7d9',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    border: '1px solid #fa5d00'
  },
  weekOpt: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 18px',
    height: 34,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    border: '1px solid rgba(29,30,28,.25)'
  },

  addBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '& p': {
      marginTop: 7,
      fontSize: 12
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 20,
      justifyContent: 'start',
      '&.MuiGrid-grid-xs-4': {
        maxWidth: '100%',
        width: '100%'
      },
      '& p': {
        display: 'none'
      }
    }
  },
  timerSectionCtn: {
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column'
    }
  },
  weekDisplayCtn: {
    height: '200px',
    flex: 1,
    marginLeft: '20px',
    [theme.breakpoints.down(900)]: {
      marginLeft: '0px'
    }
  },
  dayBox: {
    flex: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottom: '2px solid transparent',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#fff',
      borderBottomColor: '#fa5d00'
    },
    '& span': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& p': {
      color: '#1d1e1cb3',
      margin: 0
    },
    '&.MuiButton-root': {
      textTransform: 'unset !important',
      lineHeight: '1.3',
      minWidth: '20px',
      borderRadius: '0px'
    }
  },
  weekDisplay: {
    display: 'flex'
  },
  projectDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    borderTop: '1px solid #bbb',
    flex: '100%'
  },
  projectDisplayCounting: {
    borderBottom: '1px solid #bbb',
    backgroundColor: '#fff8f1'
  },
  addBtn: {
    borderRadius: 12,
    height: 64,
    width: 64,
    minWidth: 64,
    backgroundClip: 'border-box',
    backgroundColor: '#188433',
    borderColor: 'transparent!important',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#11742a'
    },
    '& span': {
      marginBottom: 5
    },
    [theme.breakpoints.down(900)]: {
      width: '100%',
      height: 33,
      fontSize: 22,
      borderRadius: 7,
      '&.MuiButton-root': {
        textTransform: 'unset !important'
      },
      '& span': {
        fontWeight: 600,
        marginBottom: 0
      }
    }
  },
  plus: {
    height: 48,
    width: 48,
    marginTop: 4,
    [theme.breakpoints.down(900)]: {
      height: 15,
      width: 15,
      marginTop: 0,
      marginRight: 4
    }
  },
  hideSpan: {
    fontSize: 15,
    display: 'none',
    [theme.breakpoints.down(900)]: {
      display: 'block'
    }
  },
  entryDetails: {
    alignSelf: 'center',
    fontSize: 15,
    '& span': {
      fontWeight: 700
    }
  },
  entryActions: {
    display: 'flex',
    gap: '10px'
  },
  entryTime: {
    fontSize: 16,
    fontWeight: 600,
    alignSelf: 'center'
  },
  entryButtonGroup: {
    display: 'flex',
    gap: '7px',
    marginLeft: 10,
    [theme.breakpoints.down(540)]: {
      flexDirection: 'column'
    }
  },
  entryStartStop: {
    color: '#1d1e1c',
    border: '1px solid rgba(29,30,28,.25)',
    boxShadow: '0 2px 2px #0000000a',
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: '17px',
    height: '40px',
    width: '97px',
    alignSelf: 'center',
    '$ span': {
      marginLeft: 5
    },
    [theme.breakpoints.down(540)]: {
      width: 'auto',
      minWidth: 'auto',
      '$ span': {
        marginLeft: 0
      }
    }
  },
  svgMarginLeft: {
    marginRight: 5,
    [theme.breakpoints.down(540)]: {
      '$ span': {
        marginLeft: 0
      }
    }
  },
  hideStart: {
    [theme.breakpoints.down(540)]: {
      display: 'none'
    }
  },
  entryEdit: {
    color: '#1d1e1c',
    alignSelf: 'center',
    border: '1px solid rgba(29,30,28,.25)',
    boxShadow: '0 2px 2px #0000000a',
    textTransform: 'none',
    borderRadius: '6px',
    fontSize: '13px',
    height: '29px',
    minWidth: 'auto'
  },
  entryResultDisplay: {
    display: 'flex',
    justifyContent: 'end',
    height: 65,
    padding: 16,
    marginRight: 168,
    flex: '100%',
    [theme.breakpoints.down(540)]: {
      marginRight: 63
    }
  },
  entryTotalText: {
    fontWeight: 500,
    fontSize: '17px',
    marginRight: 30,
    alignSelf: 'center'
  },
  modalTrackTime: {
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    position: 'absolute',
    width: '95%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    border: '1px solid rgba(29,30,28,.15)',
    borderRadius: 4,
    boxShadow: '0 1px 1px #00000004, 0 2px 4px #00000006, 0 3px 6px #0000000d, 0 4px 8px #00000013, 0 5px 10px #0000001a',
    lineHeight: 1.4,
    color: 'black'
  },
  modelTimeEntryTitle: {
    width: '100%',
    padding: '8px 0',
    textAlign: 'center',
    backgroundColor: 'rgba(29,30,28,.07)',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '1.4',
    background: 'rgba(29,30,28,.07)',
    borderBottom: '1px solid rgba(29,30,28,.25)'
  },
  dayEntryEditor: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    width: '100%'
  },
  dayEntryEditorTitle: {
    fontSize: '15px',
    fontWeight: 600,
    marginBottom: 5
  },
  newEntryInputCtn: {
    display: 'flex',
    [theme.breakpoints.down(540)]: {
      flexDirection: 'column'
    }
  },
  newEntryInputNotes: {
    resize: 'none',
    outline: 'none',
    minHeight: 54,
    width: '100%',
    maxWidth: 405,
    maxHeight: '200px',
    lineHeight: '1.4',
    backgroundColor: '#fff',
    border: '1px solid rgba(29,30,28,.3)',
    borderRadius: '6px',
    boxShadow: 'inset 0 2px #0000000a',
    color: '#1d1e1c',
    display: 'inline-block',
    fontSize: '15px',
    padding: 8,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: 0.4,
    '&:focus': {
      border: '2px solid black',
      outline: 'none'
    }
  },
  newEntryInputTimer: {
    marginLeft: 8,
    fontSize: '25px',
    height: '54px',
    outline: 'none',
    textAlign: 'right',
    backgroundColor: '#fff',
    border: '1px solid rgba(29,30,28,.3)',
    borderRadius: '6px',
    color: '#1d1e1c',
    lineHeight: '30px',
    maxWidth: '100%',
    padding: '0 8px',
    width: '100%',
    [theme.breakpoints.down(540)]: {
      marginTop: '8px',
      marginLeft: '0px'
    }
  },
  modalBtnCtn: {
    padding: 24,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(540)]: {
      flexDirection: 'column'
    }
  },
  startCancelBtnCtn: {
    display: 'flex',
    [theme.breakpoints.down(540)]: {
      flexDirection: 'column',
      marginBottom: 12
    }
  },
  startTimerBtn: {
    backgroundColor: '#188433',
    borderColor: 'transparent!important',
    color: '#fff',
    fontWeight: '500',
    border: '1px solid rgba(29,30,28,.25)',
    borderRadius: '8px',
    fontSize: '15px',
    height: '34px',
    padding: '0 16px',
    textTransform: 'none',
    width: 'auto',
    '&:hover': {
      backgroundColor: '#16672a'
    },
    [theme.breakpoints.down(540)]: {
      marginBottom: 8
    }
  },
  cancelTimerBtn: {
    marginLeft: 4,
    backgroundColor: '#fff',
    borderColor: '1px solid black',
    color: 'black',
    fontWeight: '500',
    border: '1px solid rgba(29,30,28,.25)',
    borderRadius: '8px',
    fontSize: '15px',
    height: '34px',
    padding: '0 16px',
    textTransform: 'none',
    width: 'auto',
    '&:hover': {
      border: '1px solid black'
    }
  },
  calendarEventBtn: {
    color: '#2a59c1',
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    textUnderlineOffset: '2px',
    background: 'transparent',
    textTransform: 'none',
    border: '0',
    lineHeight: 'inherit',
    padding: '0',
    [theme.breakpoints.down(540)]: {
      justifyContent: 'right'
    }
  }
}))
