
import { createTheme, responsiveFontSizes } from '@material-ui/core'

export default function createTheTheme (options) {
  const theme = createTheme({
    palette: {
      type: options?.darkTheme ? 'dark' : 'light'
    },
    overrides: {
      MuiListItemText: {
        primary: {
          fontSize: '12px'
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: '#fff'
        }
      }
    }
  }, {
    palette: {
      primary: {
        main: '#FBC337'
      }
    }
  })

  return responsiveFontSizes(theme)
}
