import React, { useState } from 'react'
import { useStyles } from './style'
import { Button, Grid, InputBase, Modal, TextareaAutosize, styled } from '@material-ui/core'
import SearchInput from '../../components/input-search'
import { devSquad, projectGroupedOptions, taskGroupedOptions, tasks } from '../../utils/projects-tasks'


const TimePage = () => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)
  
  const StyledInput = styled(InputBase)(({ theme }) => ({
    '&.Mui-focused': {
      border: '2px solid black !important', 
    },
  }));
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const days = [
    { name: 'Sun', time: '0:00' },
    { name: 'Mon', time: '10:12' },
    { name: 'Tue', time: '1:31' },
    { name: 'Wed', time: '9:45' },
    { name: 'Thu', time: '0:18' },
    { name: 'Fri', time: '6:12' },
    { name: 'Sat', time: '0:00' },
    { name: 'Week', time: '30:14' },
  ]

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.dateSectionContainer} container xs={12}>
        <Grid xs={12} className={classes.arrowTitle}>
          <Grid style={{ display: 'flex' }}>
            <Grid className={`${classes.leftArrow} ${classes.arrow}`}>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
                <line x1='19' y1='12' x2='5' y2='12' />
                <polyline points='12 19 5 12 12 5' />
              </svg>
            </Grid>

            <Grid className={`${classes.rightArrow} ${classes.arrow}`}>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
                <line x1='5' y1='12' x2='19' y2='12' />
                <polyline points='12 5 19 12 12 19' />
              </svg>
            </Grid>
          </Grid>
          <Grid>
            <h1 className={classes.date}><strong>Today:</strong> Friday, 09 Feb</h1>
          </Grid>
        </Grid>

        <Grid xs={6} className={classes.calendarTitle}>
          <Grid className={classes.calendar}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' aria-label='Calendar icon'>
              <rect x='3' y='4' width='18' height='18' rx='2' ry='2' />
              <line x1='16' y1='2' x2='16' y2='6' />
              <line x1='8' y1='2' x2='8' y2='6' />
              <line x1='3' y1='10' x2='21' y2='10' />
            </svg>
          </Grid>
          <Grid className={classes.dayOpt}>
            Day
          </Grid>
          <Grid className={classes.weekOpt}>
            Week
          </Grid>

        </Grid>

      </Grid>

      <Grid container className={classes.timerSectionCtn}>
        <Grid className={classes.addBtnContainer}>
          <Button className={classes.addBtn} onClick={handleOpenModal}>
            <svg className={classes.plus} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
              <line x1='12' y1='5' x2='12' y2='19' />
              <line x1='5' y1='12' x2='19' y2='12' />
            </svg>
            <span className={classes.hideSpan}>Track time</span>
          </Button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
          >
            <Grid container className={classes.modalTrackTime}>
              <Grid className={classes.modelTimeEntryTitle}>
                New time entry for Tuesday, 20 Feb
              </Grid>
              <Grid className={classes.dayEntryEditor}>
                <Grid className={classes.dayEntryEditorTitle}>
                  Project / Task
                </Grid>
                <SearchInput options={projectGroupedOptions} showGroupLabelOnButton={true} showDefault={devSquad} topPosition="25.8%" btnHeight="54px"/>
                <SearchInput options={taskGroupedOptions} showGroupLabelOnButton={false} showDefault={tasks} topPosition="30.4%" btnHeight="32px"/>
                <Grid className={classes.newEntryInputCtn}>
                  <TextareaAutosize
                    className={classes.newEntryInputNotes}
                    maxRows={6} 
                    placeholder="Notes (optional)"
                    aria-label="empty textarea"
                  />
                  <StyledInput
                    className={classes.newEntryInputTimer}
                    inputProps={{
                      maxLength: 5, 
                      pattern: '[0-9]{0,2}:[0-9]{0,2}',
                      title: 'Formato de hora inválido (hh:mm)',
                    }}
                    placeholder="00:00"
                  />
                </Grid>
              </Grid>
              <Grid className={classes.modalBtnCtn}>
                <Grid className={classes.startCancelBtnCtn}>
                  <Button onClick={handleCloseModal} className={classes.startTimerBtn}>
                    Start Timer
                  </Button>
                  <Button onClick={handleCloseModal} className={classes.cancelTimerBtn}>
                    Cancel
                  </Button>
                </Grid>
                <Button onClick={handleCloseModal} className={classes.calendarEventBtn}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '4px'}} width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                  Pull in a calendar event
                </Button>
              </Grid>
            </Grid>
          </Modal>
          <p>Track time</p>
        </Grid>
        <Grid className={classes.weekDisplayCtn}>
          <Grid className={classes.weekDisplay}>
            {days.map((day, index) => (
              <Button
                key={index}
                className={classes.dayBox}
                style={day.name === 'Week' ? { alignItems: 'flex-end' } : (day.name === 'Thu' ? { borderBottomColor: '#fa5d00' } : {})}
              >
                <p>{day.name}</p>
                <p>{day.time}</p>
              </Button>
            ))}
          </Grid>

          <Grid className={classes.projectDisplay}>
            <Grid className={classes.entryDetails}>
              <Grid>
                <span>Darvest</span> (DevSquad)
              </Grid>
              <Grid>
                Dev: Development
              </Grid>
            </Grid>
            <Grid className={classes.entryActions}>
              <Grid className={classes.entryTime}>
                0:18
              </Grid>
              <Grid className={classes.entryButtonGroup}>
                <Button className={classes.entryStartStop}>
                  <svg className={classes.svgMarginLeft} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' aria-hidden='true'>
                    <circle cx='12' cy='12' r='10' />
                    <polyline points='12 6 12 12' class='clock-running-minute-hand' />
                    <polyline points='12 12 16 14' class='clock-running-hour-hand' />
                  </svg>
                  <span className={classes.hideStart}>
                    Start
                  </span>
                </Button>
                <Button className={classes.entryEdit}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.projectDisplay}>
            <Grid className={classes.entryDetails}>
              <Grid>
                <span>Darvest</span> (DevSquad)
              </Grid>
              <Grid>
                Meeting: Tech Talk
              </Grid>
            </Grid>
            <Grid className={classes.entryActions}>
              <Grid className={classes.entryTime}>
                2:01
              </Grid>
              <Grid className={classes.entryButtonGroup}>
                <Button className={classes.entryStartStop}>
                  <svg className={classes.svgMarginLeft} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' aria-hidden='true'>
                    <circle cx='12' cy='12' r='10' />
                    <polyline points='12 6 12 12' class='clock-running-minute-hand' />
                    <polyline points='12 12 16 14' class='clock-running-hour-hand' />
                  </svg>
                  <span className={classes.hideStart}>
                    Start
                  </span>
                </Button>
                <Button className={classes.entryEdit}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`${classes.projectDisplay} ${classes.projectDisplayCounting} `}>
            <Grid className={classes.entryDetails}>
              <Grid>
                <span>Darvest</span> (DevSquad)
              </Grid>
              <Grid>
                Dev: Pair Programming
              </Grid>
            </Grid>
            <Grid className={classes.entryActions}>
              <Grid className={classes.entryTime}>
                3:48
              </Grid>
              <Grid className={classes.entryButtonGroup}>
                <Button style={{ backgroundColor: 'black', color: '#fff' }} className={classes.entryStartStop}>
                  <svg className={classes.svgMarginLeft} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' aria-hidden='true'>
                    <circle cx='12' cy='12' r='10' />
                    <polyline points='12 6 12 12' class='clock-running-minute-hand' />
                    <polyline points='12 12 16 14' class='clock-running-hour-hand' />
                  </svg>
                  <span className={classes.hideStart}>
                    Stop
                  </span>
                </Button>
                <Button className={classes.entryEdit}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.entryResultDisplay}>
            <Grid className={classes.entryTotalText}>
              <Grid>Total: </Grid>
            </Grid>
            <Grid className={classes.entryActions}>
              <Grid className={classes.entryTime}>
                6:07
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  )
}

export default TimePage

