import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AppToolbar, AppFooter } from './components'
import Routes from './routes'
import { useStyles } from './style'
import Logger from './utils/logger'
import RootContextProvider from './contexts/root'

const customBodyStyle = {
  backgroundColor: '#fff',
  color: 'black'
}

function App () {
  const classes = useStyles()
  return (
    <RootContextProvider>
      <div className={classes.root} style={customBodyStyle}>
        <Logger />
        <CssBaseline />
        <AppToolbar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </main>
        <footer>
          <AppFooter />
        </footer>
        
      </div>
    </RootContextProvider>
  )
}

export default App
